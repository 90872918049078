import { Power3, TimelineLite } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import React, { useEffect, useRef, useContext } from "react"
import { CtaArrowAnimationElement } from "./styled"
import { PageContext } from '../../context/PageManager/PageManager';
//without this line, CSSPlugin may get dropped by your bundler...
const plugins = [CSSPlugin]

type CtaArrowAnimationProps = {}

export const CtaArrowAnimation: React.FC<CtaArrowAnimationProps> = ({
  children,
}) => {
  const pageContext = useContext(PageContext)
  const scrollCTAArrowRef = useRef<HTMLDivElement>(null)
  const timeline = new TimelineLite({ paused: true })
  const options = {
    threshold: 0.75,
  }

  const obeserverCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && pageContext.pageLoaded) {
        timeline.play()
      } 
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(obeserverCallback, options)

    timeline.staggerFromTo(
      scrollCTAArrowRef.current,
      1.3,
      { opacity: 0, y: -200 },
      { opacity: 1, y: 0, ease: Power3.easeOut },
      .5
    )

    if (scrollCTAArrowRef.current) observer.observe(scrollCTAArrowRef.current)
  }, [pageContext.pageLoaded])

  return (
    <CtaArrowAnimationElement ref={scrollCTAArrowRef}>
      {children}
    </CtaArrowAnimationElement>
  )
}
