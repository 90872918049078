import styled from "styled-components/macro"

export const CtaArrowAnimationElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  height: 100%;
  display: flex;
  align-items: center;
`
