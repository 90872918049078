import { TransitionState } from "gatsby-plugin-transition-link"
import { Expo, TimelineLite } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react"
import { FontFamily, FontWeight } from "../../../data/style/variables"
import {
  Subtitle,
  TitleH2,
  Typography,
  TypographyTypes,
} from "../Typography/Typography"
import { TypographyAnimation } from "../TypographyAnimation/TypographyAnimation"
import {
  AnimationSectionBlockElement,
  ContainerElement,
  DetailTextContainerElement,
  GridAnimationContainer,
  GridElementProps,
  GridInfoContainerElement,
  GridLineElement,
  GridLinesElement,
  GridTitleContainerElement,
  InnerContainerElement,
  SectionContainer,
  GridContainerElement
} from "./styled"
import { PageContext } from "../../context/PageManager/PageManager"

const plugins = [CSSPlugin]

type GridProps = {
  gridElementProps: GridElementProps
  title?: string | React.ReactElement
  subTitle?: string | React.ReactElement
  detailText?: string
}

export const Grid: React.FC<GridProps> = ({
  gridElementProps,
  title,
  subTitle,
  children,
  detailText,
}) => {
  const [transitionStatus, setTransitionStatus] = useState<string>()
  const lineRefs = useRef<(HTMLDivElement | null)[]>([])
  const sectionContainerRef = useRef<HTMLDivElement>(null)
  const gridInfoContainerRef = useRef<HTMLDivElement>(null)
  const pageContext = useContext(PageContext)

  useEffect(() => {
    if (transitionStatus === "exiting") {
      handlePageOutTranstition()
    } else {
      handlePageInTranstition()
    }
  }, [transitionStatus])

  const handlePageInTranstition = useCallback(() => {
    const timeline = new TimelineLite()

    timeline
      .to(
        lineRefs.current,
        1,
        {
          ease: Expo.easeOut,
          x: "100%",
        },
        0.2
      )
      .fromTo(
        gridInfoContainerRef.current,
        1,
        {
          opacity: 0,
        },
        {
          ease: Expo.easeOut,
          opacity: 1,
          onComplete: () => {
            pageContext.setPageLoaded(true)
          },
        },
        0.2
      )
  }, [lineRefs, gridInfoContainerRef])

  const handlePageOutTranstition = useCallback(() => {
    const timeline = new TimelineLite()
    pageContext.setPageLoaded(false)

    timeline
      .fromTo(
        lineRefs.current,
        1,
        {
          x: "-100%",
          width: "100%",
        },
        {
          ease: Expo.easeOut,
          x: 0,
        },
        0.2
      )
      .fromTo(
        gridInfoContainerRef.current,
        1,
        {
          opacity: 1,
        },
        {
          ease: Expo.easeOut,
          opacity: 0.1,
        },
        0.2
      )
      .fromTo(
        sectionContainerRef.current,
        1,
        {
          opacity: 1,
        },
        {
          ease: Expo.easeOut,
          opacity: 0.1,
        },
        0.2
      )
  }, [lineRefs, gridInfoContainerRef, sectionContainerRef])

  const saveAnimationRef = useCallback(r => lineRefs.current.push(r), [
    lineRefs,
  ])

  return (
    <TransitionState>
      {({ transitionStatus }) => {
        setTransitionStatus(transitionStatus)

        return (
          <GridContainerElement>
            <ContainerElement
              baseHeight={gridElementProps.baseHeight}
              background={gridElementProps.background}
              backgroundImage={gridElementProps.backgroundImage}
              spaced={gridElementProps.spaced}
              noOverFlowHidden={gridElementProps.noOverFlowHidden}
              noPaddingMobile={gridElementProps.noPaddingMobile}
              noAutoHeightOnMobile={gridElementProps.noAutoHeightOnMobile}
              noPaddingBottom={gridElementProps.noPaddingBottom}
            >
              {gridElementProps.backgroundImage && (
                <GridAnimationContainer>
                  <AnimationSectionBlockElement
                    ref={saveAnimationRef}
                    background={gridElementProps.background}
                  />
                </GridAnimationContainer>
              )}
              <InnerContainerElement
                noPadding={gridElementProps.noPadding}
                noPaddingMobile={gridElementProps.noPaddingMobile}
                noPaddingBottom={gridElementProps.noPaddingBottom}
                lineColor={gridElementProps.lineColor}
              >
                <GridInfoContainerElement
                  ref={gridInfoContainerRef}
                  largeInfoPadding={gridElementProps.largeInfoPadding}
                >
                  {detailText && (
                    <DetailTextContainerElement>
                      <Typography
                        titleType={TypographyTypes.p}
                        fontWeight={FontWeight.Regular}
                        fontFamily={FontFamily.RobotoMono}
                        fontSize={"1.4rem"}
                        color={gridElementProps.detailTextColor}
                      >
                        {detailText}
                      </Typography>
                    </DetailTextContainerElement>
                  )}
                  {title && typeof title === "string" && (
                    <GridTitleContainerElement>
                      <TypographyAnimation>
                        <TitleH2 color={gridElementProps.color}>
                          {title}
                        </TitleH2>
                      </TypographyAnimation>
                    </GridTitleContainerElement>
                  )}
                  {title && typeof title === "object" && (
                    <GridTitleContainerElement>
                      <TypographyAnimation>{title}</TypographyAnimation>
                    </GridTitleContainerElement>
                  )}
                  {subTitle && (
                    <GridTitleContainerElement>
                      <Subtitle color={gridElementProps.color}>
                        {subTitle}
                      </Subtitle>
                    </GridTitleContainerElement>
                  )}
                </GridInfoContainerElement>
                <GridLinesElement>
                  <GridLineElement lineColor={gridElementProps.lineColor}>
                    <AnimationSectionBlockElement
                      ref={saveAnimationRef}
                      background={gridElementProps.background}
                    />
                  </GridLineElement>
                  <GridLineElement lineColor={gridElementProps.lineColor}>
                    <AnimationSectionBlockElement
                      ref={saveAnimationRef}
                      background={gridElementProps.background}
                    />
                  </GridLineElement>
                  <GridLineElement lineColor={gridElementProps.lineColor}>
                    <AnimationSectionBlockElement
                      ref={saveAnimationRef}
                      background={gridElementProps.background}
                    />
                  </GridLineElement>
                </GridLinesElement>
                <SectionContainer
                  ref={sectionContainerRef}
                  column={gridElementProps.column}
                >
                  {children}
                </SectionContainer>
              </InnerContainerElement>
              {gridElementProps.backgroundImage && (
                <GridAnimationContainer>
                  <AnimationSectionBlockElement
                    ref={saveAnimationRef}
                    background={gridElementProps.background}
                  />
                </GridAnimationContainer>
              )}
            </ContainerElement>
          </GridContainerElement>
        )
      }}
    </TransitionState>
  )
}
