import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { RoutePath } from "../../../../../data/RoutePath"
import {
  BlackGridTheme,
  Color,
  GrayGridThemeBlackText,
  WhiteGridTheme
} from "../../../../../data/style/variables"
import { Bold } from "../../../../general/Bold/Bold"
import { CtaArrow, Rotations, Sizes } from "../../../../general/CtaArrow/CtaArrow"
import { CtaArrowAnimation } from "../../../../general/CtaArrowAnimation/CtaArrowAnimation"
import { Subtitle, TitleH3, Typography, TypographyTypes } from "../../../../general/Typography/Typography"
import { TypographyAnimation } from "../../../../general/TypographyAnimation/TypographyAnimation"
import {
  ContactBlockElement,
  ContainerElement,
  CtaArrowContainerElement,
  FooterListElement,
  FooterListItemElement,
  InfoLinkContainer,
  TitleContainerElement
} from "./styled"

type FooterProps = {
  spacing?: boolean
}

export const Footer: React.FC<FooterProps> = ({ spacing }) => {
  return (
    <>
      {spacing && (
        <Grid
          gridElementProps={{
            baseHeight: "175px",
            ...GrayGridThemeBlackText,
            detailTextColor: Color.DarkGray,
          }}
        ></Grid>
      )}
      <ContainerElement id="footer-contact">
        <Grid
          gridElementProps={{
            baseHeight: "675px",
            ...WhiteGridTheme,
            noOverFlowHidden: true,
            detailTextColor: Color.DarkGray,
            noPaddingBottom: true,
            color: Color.Black,
          }}
          title={"Werk met ons"}
          detailText={"CONTACT / EOF"}
          subTitle={
            <Subtitle color={Color.Black}>
              Om het door u gewenste eindresultaat te bereiken werken we
              graag nauw met u samen. Uw kennis van de markt, uw processen en uw
              afnemers is onmisbare input voor ons om de juiste software of
              applicatie te ontwikkelen. We verkopen geen wonderen maar samen
              verzetten we bergen.
            </Subtitle>
          }
        >
          <Section/>
          <Section/>
          <Section sectionElementProps={{ justifyCenter: true }}>
            <CtaArrowAnimation>
              <TitleContainerElement>
                <CtaArrowContainerElement>
                  <CtaArrow
                    rotation={Rotations.down}
                    color={Color.LightGray}
                    size={Sizes.large}
                    origin="center"
                  />
                </CtaArrowContainerElement>
              </TitleContainerElement>
            </CtaArrowAnimation>
          </Section>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...BlackGridTheme,
            detailTextColor: Color.DarkGray,
          }}
        >
          <Section>
            <FooterListElement>
              <TitleH3 color={Color.DarkGray}>
                <Bold background={Color.DarkGray}>Producten</Bold>
              </TitleH3>
              <TypographyAnimation>
                <FooterListItemElement
                  to={RoutePath.Checklist}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>Checklist</u>
                  </Typography>
                </FooterListItemElement>
                <FooterListItemElement
                  to={RoutePath.PowerBi}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>Power Bi</u>
                  </Typography>
                </FooterListItemElement>
                <FooterListItemElement
                  to={RoutePath.Custom}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>Op maat</u>
                  </Typography>
                </FooterListItemElement>
                <FooterListItemElement
                  to={RoutePath.Cad}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>3D CAD</u>
                  </Typography>
                </FooterListItemElement>
                <FooterListItemElement
                  to={RoutePath.Erp}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>ERP</u>
                  </Typography>
                </FooterListItemElement>
              </TypographyAnimation>
            </FooterListElement>
          </Section>
          <Section>
            <FooterListElement>
              <TitleH3 color={Color.DarkGray}>
                <Bold background={Color.DarkGray}>Services</Bold>
              </TitleH3>
              <TypographyAnimation>
                <FooterListItemElement
                  to={RoutePath.TechnicalSoftware}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>Technische software</u>
                  </Typography>
                </FooterListItemElement>
                <FooterListItemElement
                  to={RoutePath.Cloud}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>Cloud oplossingen</u>
                  </Typography>
                </FooterListItemElement>
                <FooterListItemElement
                  to={RoutePath.WebAndApps}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.White}
                    fontSize={"2.3rem"}
                  >
                    <u>Web & Apps</u>
                  </Typography>
                </FooterListItemElement>
              </TypographyAnimation>
            </FooterListElement>
          </Section>
          <Section sectionElementProps={{ justifyCenter: true }}/>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "235px",
            ...BlackGridTheme,
            detailTextColor: Color.DarkGray,
            noPaddingBottom: true,
            noPadding: true,
          }}
        >
          <Section sectionElementProps={{ justifyCenter: true }}>
            <ContactBlockElement>
              <TypographyAnimation>
                <TitleH3 color={Color.DarkGray}>Mail ons</TitleH3>
                <Typography
                  titleType={TypographyTypes.p}
                  color={Color.White}
                  fontSize={"2.3rem"}
                >
                  info@discbv.nl
                </Typography>
              </TypographyAnimation>
            </ContactBlockElement>
          </Section>
          <Section sectionElementProps={{ justifyCenter: true }}>
            <ContactBlockElement>
              <TypographyAnimation index={1}>
                <TitleH3 color={Color.DarkGray}>Bel ons</TitleH3>
                <Typography
                  titleType={TypographyTypes.p}
                  color={Color.White}
                  fontSize={"2.3rem"}
                >
                  038-3332300
                </Typography>
              </TypographyAnimation>
            </ContactBlockElement>
          </Section>
          <Section sectionElementProps={{ justifyCenter: true }}>
            <ContactBlockElement>
              <TypographyAnimation index={2}>
                <TitleH3 color={Color.DarkGray}>Kom langs</TitleH3>
                <Typography
                  titleType={TypographyTypes.p}
                  color={Color.White}
                  fontSize={"2.3rem"}
                >
                  Vrouwenlaan 102 / 8017HS Zwolle
                </Typography>
              </TypographyAnimation>
            </ContactBlockElement>
          </Section>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...WhiteGridTheme,
            detailTextColor: Color.DarkGray,
            noPaddingBottom: true,
            noPadding: true,
            noPaddingMobile: true
          }}
        >
          <Section sectionElementProps={{ justifyCenter: true }}>
              <TypographyAnimation>
                <InfoLinkContainer>
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.Black}
                    fontSize={"1.6rem"}
                    noMargin
                    href="https://www.softwarebedrijf-info.nl/bdr3192382-Disc.html"
                    target="_blank"
                  >
                    <b>Disc</b> is ook te vinden op www.softwarebedrijf-info.nl.
                  </Typography>
                </InfoLinkContainer>
              </TypographyAnimation>
          </Section>
          <Section sectionElementProps={{ justifyCenter: true }} />
          <Section sectionElementProps={{ justifyCenter: true }} />
        </Grid>
      </ContainerElement>
    </>
  )
}
