import React from "react"
import { SectionElement, SectionElementProps } from "./styled"

type SectionProps = {
  sectionElementProps?: SectionElementProps
}

export const Section: React.FC<SectionProps> = ({
  sectionElementProps,
  children,
}) => {
  return (
    <>
      <SectionElement
        alignCenter={sectionElementProps?.alignCenter}
        justifyCenter={sectionElementProps?.justifyCenter}
        justifyEnd={sectionElementProps?.justifyEnd}
        color={sectionElementProps?.color}
        background={sectionElementProps?.background}
        noPadding={sectionElementProps?.noPadding}
        noPaddingTop={sectionElementProps?.noPaddingTop}
        width={sectionElementProps?.width}
        grow={sectionElementProps?.grow}
      >
        {children}
      </SectionElement>
    </>
  )
}
