import styled from "styled-components/macro"
import { Color } from "../../../data/style/variables"

export type SectionElementProps = Partial<{
  color?: string
  alignCenter?: boolean
  justifyCenter?: boolean
  justifyEnd?: boolean
  noPadding?: boolean
  noPaddingTop?: boolean
  background?: Color
  width?: string
  grow?: number
}>

export const SectionElement = styled.div<SectionElementProps>`
  color: ${props => (props.color ? props.color : "black")};
  display: flex;
  align-items: ${props => (props.alignCenter ? "center" : "unset")};
  justify-content: ${props =>
    props.justifyCenter ? "center" : props.justifyEnd ? "flex-end" : "unset"};
  background: ${props => (props.background ? props.background : "transparent")};
  flex-direction: column;
  flex: 1 1 0;
  padding: ${props => (props.noPadding ? "0" : "25px")};
  padding-top: ${props => (props.noPaddingTop ? "0" : "25px")};
  position: relative;
  @media (min-width: 769px) {
    width: ${props => (props.width ? props.width : "33.33%")};
  }
  @media (max-width: 768px) {
    padding-bottom: 0;
    padding-top: 0;
  }
  flex-grow: ${props => (props.grow ? `${props.grow + .14}` : "1")};
  flex: 1;
`
