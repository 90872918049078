import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components/macro"
import { Color } from "../../../../../data/style/variables"

export const ContainerElement = styled.div`
  display: flex;
`

export const TitleContainerElement = styled.div`
  margin-bottom: 50px;
`

export const ContactBlockContainerElement = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
`

export const ContactBlockElement = styled.div`
  background: ${Color.Black};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`

export const FooterListElement = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterListItemElement = styled(TransitionLink)`
  display: inline-block;
  margin-bottom: 1rem;
`

export const CtaArrowContainerElement = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`
export const InfoLinkContainer = styled.div`
  padding: 1.5rem 0;
`
