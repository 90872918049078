import { Power1, TimelineLite } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import React, {
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react"
import { TypographyAnimationContainerElement } from "./styled"
import { PageContext } from "../../context/PageManager/PageManager"
//without this line, CSSPlugin may get dropped by your bundler...
const plugins = [CSSPlugin]

type TypographyAnimationProps = {
  speed?: number
  reverseOn?: boolean
  // Will delay based on delay variable inside animation.
  // So index 1, 2, 3: 1 will play then + delay -> 2 will play then + delay -> three will play.
  index?: number
}

export const TypographyAnimation: React.FC<TypographyAnimationProps> = ({
  speed,
  reverseOn,
  index,
  children,
}) => {
  const pageContext = useContext(PageContext)
  const refs = useRef<(HTMLDivElement | null)[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const timeline = new TimelineLite({ paused: true })
  const options = {
    threshold: 0.75,
  }

  const obeserverCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && pageContext.pageLoaded) {
        window.setTimeout(() => timeline.play(), index ? index * 0.3 * 1000 : 0)
      } else if (reverseOn) {
        timeline.reverse()
      }
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(obeserverCallback, options)

    timeline.staggerFromTo(
      refs.current,
      0.7,
      {
        ease: Power1.easeOut,
        opacity: 0,
        x: -7,
      },
      {
        ease: Power1.easeOut,
        opacity: 1,
        x: 0,
      },
      speed ? speed : 0.3
    )

    if (containerRef.current) observer.observe(containerRef.current)

    // Remove the observer when component leaves scope,
    // this fixes repeating animations when switching or going back in browsers.
    return () => {
      observer.disconnect()
    }
  }, [pageContext.pageLoaded])

  return (
    <TypographyAnimationContainerElement ref={containerRef}>
      {React.Children.map(children, (el, i) => {
        return useMemo(
          () => (
            <div
              style={{ opacity: 0, position: "relative" }} // , overflow: "hidden"  <-- if something is wrong with animation at any point. Try to add this.
              key={i}
              ref={r => refs.current.push(r)}
            >
              {el}
            </div>
          ),
          []
        )
      })}
    </TypographyAnimationContainerElement>
  )
}
