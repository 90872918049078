import styled from "styled-components/macro"
import { Color } from "../../../data/style/variables"

export type GridElementProps = Partial<{
  background?: string
  backgroundImage?: string
  baseHeight: string
  lineColor: string
  color?: Color
  noPadding?: boolean
  noPaddingMobile?: boolean
  detailTextColor: Color
  spaced?: boolean
  noOverFlowHidden?: boolean
  noAutoHeightOnMobile?: boolean
  largeInfoPadding?: boolean
  noPaddingBottom?: boolean
  column?: boolean
}>

export const ContainerElement = styled.div<GridElementProps>`
  position: relative;
  overflow: ${props => (props.noOverFlowHidden ? "visible" : "hidden")};
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: ${props => (props.baseHeight ? `${props.baseHeight}` : "500px")};
  background: ${props =>
    props.backgroundImage
      ? props.backgroundImage
      : props.background
      ? props.background
      : "transparent"};
  @media (max-width: 768px) {
    height: ${props =>
      props.noAutoHeightOnMobile ? props.baseHeight : "auto"};
    margin: 0;
    padding: 0;
  }
  @media (min-width: 1440px) {
    margin: 0 ${props => (props.spaced ? "80px" : "0")};
  }
`

export const InnerContainerElement = styled.div<GridElementProps>`
  padding-top: ${props => (props.noPadding ? `${0}px` : `${75}px`)};
  padding-bottom: 75px;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  margin: 0 auto;
  position: relative;
  border-left: 1px solid
    ${props => (props.lineColor ? props.lineColor : "black")};
  padding-bottom: ${props => (props.noPaddingBottom ? `${0}px` : `${75}px`)};
  @media (max-width: 768px) {
    padding-top: ${props => (props.noPaddingMobile ? `${0}px` : `${75}px`)};
  }
`

export const SectionContainer = styled.div<GridElementProps>`
  display: flex;
  height: 100%;
  flex-direction: ${props => (props.column ? "column" : "row")};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const GridTitleContainerElement = styled.div<GridElementProps>`
  height: auto;
  padding: 0 25px;
  max-width: 835px;
  z-index: 1;
  position: relative;
`

export const GridLinesElement = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
`

export const GridLineElement = styled.div<GridElementProps>`
  height: 100%;
  overflow: hidden;
  @media (min-width: 769px) {
    &:not(:first-child) {
      border-left: 1px solid
        ${props => (props.lineColor ? props.lineColor : "black")};
    }
    &:last-child {
      border-right: 1px solid
        ${props => (props.lineColor ? props.lineColor : "black")};
    }
  }
  width: 33.33%;
`

export const GridAnimationContainer = styled.div`
  width: auto;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`

export const DetailTextContainerElement = styled.div`
  position: absolute;
  transform: rotate(90deg);
  left: -30px;
  /* top: 153px; */
  transform-origin: left;
`

type ABlockElementProps = {
  background?: string
  largeInfoPadding?: boolean
}

export const AnimationSectionBlockElement = styled.div<ABlockElementProps>`
  width: calc(100% + 1px);
  height: 100%;
  transform: translateX(0);
  background: ${props =>
    props.background ? props.background : Color.MainGray};
  z-index: 9;
  position: relative;
`
export const GridInfoContainerElement = styled.div<ABlockElementProps>`
  z-index: 1;
  @media (max-width: 768px) {
    padding: ${props => (props.largeInfoPadding ? "50px" : 0)};
  }
`
export const GridContainerElement = styled.div`
  display: flex;
  width: 100%;
`
