import styled from "styled-components/macro";
import { Rotations } from './CtaArrow';

type ArrowElementProps = {
  rotation: Rotations;
  origin?: string;
}

export const ArrowElement = styled.svg<ArrowElementProps>`
  transform: rotate(${props => props.rotation}deg);
  position: relative;
  transform-origin: ${props => props.origin ? props.origin : 'top'};
`;