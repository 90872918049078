import React from "react"
import { ArrowElement } from "./styled"
import { Color } from "../../../data/style/variables"

export enum Sizes {
  small = 85,
  medium = 110,
  large = 220,
}

export enum Rotations {
  up = 180,
  down = 0,
  left = 90,
  right = -90,
}

type CtaArrowProps = {
  size: Sizes
  rotation: Rotations
  color: Color
  origin?: string
}

export const CtaArrow: React.FC<CtaArrowProps> = ({
  color,
  size,
  rotation,
  origin
}) => {
  return (
    <ArrowElement
      origin={origin}
      rotation={rotation}
      height={size}
      viewBox="0 0 24 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9393 184.061C11.5251 184.646 12.4749 184.646 13.0607 184.061L22.6066 174.515C23.1924 173.929 23.1924 172.979 22.6066 172.393C22.0208 171.808 21.0711 171.808 20.4853 172.393L12 180.879L3.51473 172.393C2.92894 171.808 1.97919 171.808 1.39341 172.393C0.807619 172.979 0.807619 173.929 1.39341 174.515L10.9393 184.061ZM10.5 6.55671e-08L10.5 183L13.5 183L13.5 -6.55671e-08L10.5 6.55671e-08Z"
        fill={color}
      />
    </ArrowElement>
  )
}
